<template>
  <div>
    <div class="sales-list">
      <div><b>Liste des ventes déjà effectuées</b></div>
      <div v-for="sale of list" :key="sale.id" class="sale-line">
        <b-row>
          <b-col cols="2">{{ sale.createdOn | dateToString }}</b-col>
          <b-col cols="4">{{ sale.label }}</b-col>
          <b-col  cols="4" class="text-right">
            <div class="discounts" v-if="sale.discounts">
              <div class="badge badge-light" v-for="discount in sale.discounts" :key="discount.id">
                <span v-if="discount.amount">-{{ discount.amount }}€</span>
                <span v-if="discount.percentage">-{{ discount.percentage }}%</span>
                <span v-if="discount.comments" v-b-tooltip="discount.comments">
                  &nbsp;<i class="fa fa-info-circle"></i>
                </span>
              </div>
            </div>
            {{ sale.price | currency }}
          </b-col>
          <b-col cols="2">
            <invoice-badge
              :invoice="sale.invoice" v-if="sale.showInvoice()" :no-link="noLink"
            ></invoice-badge>
            <div v-if="canDeleteSale(sale)">
              <b-form-checkbox
                :id="'delete' + sale.id"
                :checked="isDeleted(sale)"
                @change="toggleDeleteSale($event, sale)"
              >
                Annuler la vente
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import InvoiceBadge from './InvoiceBadge'
import { existsIn } from '@/utils/arrays'

export default {
  name: 'salesList',
  components: {
    InvoiceBadge,
  },
  props: {
    list: Array,
    noLink: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Array,
      default: function() { return [] },
    },
  },
  data() {
    return {
      deleteSales: [],
    }
  },
  computed: {
  },
  watch: {
    list: function() {},
  },
  methods: {
    canDeleteSale(sale) {
      return existsIn([sale.id], this.canDelete.map(elt => elt.id))
    },
    isDeleted(sale) {
      return existsIn([sale.id], this.deleteSales.map(elt => elt.id))
    },
    toggleDeleteSale(value, sale) {
      if (value) {
        this.deleteSales.push(sale)
      } else {
        const index = this.deleteSales.map(elt => elt.id).indexOf(sale.id)
        if (index >= 0) {
          this.deleteSales.splice(index, 1)
        }
      }
      this.deleteSales = this.deleteSales.concat()
      this.$emit('delete', { sales: this.deleteSales, })
    },
  },
  mounted() {
  },
}
</script>
<style scoped lang="less">
  .discounts {
    display: inline-block;
  }
  .sale-line {
    font-size: 12px;
    padding: 5px 0;
    border-bottom: solid 1px #ccc;
  }
  .sales-list {
    margin-bottom: 20px;
  }
</style>
