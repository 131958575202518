<template>
  <div v-if="entity !== null && canView">
    <div>
      <div class="activities-field-group-block field-group-block">
        <div class="field-group-header">
          <b-row>
            <b-col cols="4">
              <b>{{ title }}</b>
            </b-col>
            <b-col cols="4">
              <b-select id="displayMode" v-model="selectedDisplayMode" class="small-select">
                <b-select-option :value="elt" v-for="elt of displayModes" :key="elt.id">
                  {{ elt.name }}
                </b-select-option>
              </b-select>
            </b-col>
            <b-col cols="4" class="text-right">
              <span v-if="showPrintJustificative">
                <a
                  class="btn btn-secondary btn-sm"
                  href
                  @click.prevent="showPrintJustificative = false"
                >
                  <i class="fa fa-close"></i> Retour
                </a>
                <a
                  class="btn btn-primary btn-sm"
                  href
                  :class="selectedInscriptions.length ? '' : 'disabled'"
                  @click.prevent="printJustificative()"
                >
                  <i class="fa fa-file-pdf"></i> PDF
                </a>
              </span>
              <span v-else>
                <a
                  class="btn btn-secondary btn-sm"
                  href
                  @click.prevent="showPrintDoc(true)"
                  :class="editDisabled ? 'disabled' : ''"
                  v-if="!showForm && showAll && canChange && !allowEdit"
                >
                  <i class="fa fa-file-pdf"></i> Devis
                </a>
                <a
                  class="btn btn-secondary btn-sm"
                  href
                  @click.prevent="showPrintDoc(false)"
                  :class="editDisabled ? 'disabled' : ''"
                  v-if="!showForm && showAll && canChange && !allowEdit"
                >
                  <i class="fa fa-file-pdf"></i> Justificatifs
                </a>
                <a
                  class="btn btn-primary btn-sm btn-edit-child"
                  href
                  @click.prevent="onAdd()"
                  :class="editDisabled ? 'disabled' : ''"
                  v-if="!showForm && showAll && canChange"
                >
                  <i class="fa fa-plus"></i> Ajouter
                </a>
              </span>
            </b-col>
          </b-row>
        </div>
        <div v-if="!showForm">
          <div class="field-line field-line-no-border">
            <tabs-bar
              :tabs="tabs"
              :active="activeTab"
              :enabled="!editDisabled"
              secondary
              @change="tabChanged($event)"
            ></tabs-bar>
          </div>
          <loading-gif :loading-name="entityActivitiesLoadingName"></loading-gif>
          <div v-if="!isLoading(entityActivitiesLoadingName)">
            <div v-if="filteredInscriptions.length === 0" class="field-line">
              <div v-if="youthHome">Aucune inscription {{youthHomeLabel}}</div>
              <div v-else>Aucune inscription aux activités</div>
            </div>
            <div v-if="showPrintJustificative" class="field-line text-right">
              <b-form-checkbox
                id="adhesion-justificatif"
                v-model="includeAdhesionInJustificative"
                name="adhesion-justificatif"
                :value="true"
                :unchecked-value="false"
              >
                Inclure les adhésions dans le <span v-if="isQuote">devis</span><span v-else>justificatif</span>
              </b-form-checkbox>
            </div>
            <div
              v-for="inscription in filteredInscriptions"
               v-bind:key="inscription.id"
               class="field-line"
              :class="showAll ? '' : 'small-row'"
            >
              <b-row>
                <b-col :cols="showAll ? 10 : 12" :class="{ 'small-col': !showAll, }">
                  <div
                    class="main-line"
                    :style="inscription.activity.getHeaderStyle()"
                  >
                    <b-row>
                      <b-col cols="6">
                        <span class="badge" :style="inscription.activity.schoolYear.getHeaderStyle()">
                          {{ inscription.activity.schoolYear.name }}
                        </span>&nbsp;
                        {{ inscription.activity.name }}
                      </b-col>
                      <b-col cols="6" class="text-right">
                        <sale-price :elt="inscription"></sale-price>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row>
                    <b-col cols="9">
                      {{ inscription.individual.firstAndLastName() }}
                      <span class="badges-col">
                        <span
                          class="badge"
                          :class="inscription.isTryingFinished() ? 'badge-danger' : 'badge-success'"
                          v-if="inscription.trying"
                        >
                          <i class="fa fa-warning" v-if="inscription.isTryingFinished()"></i>
                          À l'essai
                          <span v-if="inscription.tryingDate">
                            jusqu'au {{ inscription.tryingDate | dateToString }}
                          </span>
                        </span>
                        <span class="badge badge-warning" v-if="inscription.waiting">En attente</span>
                        <span class="badge badge-danger" v-if="inscription.refusedOn">Refusée</span>
                        <span
                          class="badge badge-danger"
                          v-else-if="inscription.cancelled"
                        >
                          Annulée
                          <span v-if="inscription.cancelledOn">
                            le {{ inscription.cancelledOn | dateToString }}
                          </span>
                          <span v-if="inscription.cancelledBy">
                            par {{ inscription.cancelledBy }}
                          </span>
                        </span>
                        <span class="badge badge-warning" v-if="!inscription.confirmed">À confirmer</span>
                        <span class="badge badge-danger" v-if="inscription.hasLeftOn">
                          a quitté le {{ inscription.hasLeftOn | dateToString }}
                        </span>
                      </span>
                      <span v-if="inscription.activity.isEvent() && inscription.seats.length">
                        <span class="seats" v-for="seat of inscription.seats" :key="seat.id">
                          {{ seat.name }} {{ seat.price | currency }}:
                          <counter-label :counter="seat.seats" label="place"></counter-label>
                        </span>
                      </span>
                    </b-col>
                    <b-col class="text-right">
                      <div v-if="inscription.activityAnalyticAccount.id" class="badge badge-light">
                        {{ inscription.activityAnalyticAccount.name }}
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="inscription.comments">
                    <b-col>
                      <div class="comments">{{ inscription.comments }}</div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col :cols="showAll ? 2 : 12" class="text-right" :class="{ 'small-col': !showAll, }">
                  <div v-if="entity.id === inscription.entity.id">
                    <div v-for="sale of inscription.getSales()" :key="sale.id">
                      <invoice-badge
                        v-if="sale && !sale.ignored && sale.price"
                        :invoice="sale.invoice"
                        :entity="entity"
                      >
                      </invoice-badge>
                    </div>
                  </div>
                  <div v-else class="small-text">
                    <router-link :to="getEntityLink(inscription.entity)">
                      {{ inscription.entity.name }}
                    </router-link>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="small grayed">
                    Le {{ inscription.createdOn|dateToString('DD/MM/YYYY') }}
                    par {{ inscription.createdBy|defaultValue('-') }}
                  </div>
                </b-col>
                <b-col class="text-center">
                  <activity-extra-sale-button
                    :inscription="inscription"
                    :entity="entity"
                    :youth-home="youthHome"
                    @done="refresh()"
                  >
                  </activity-extra-sale-button>
                </b-col>
                <b-col class="text-right">
                  <div v-if="showPrintJustificative">
                    <b-form-checkbox
                      :id="'justificatif-' + inscription.id"
                      :checked="isInscriptionSelected(inscription)"
                      :name="'justificatif-' + inscription.id"
                      :value="true"
                      :unchecked-value="false"
                      @change="selectInscription(inscription, $event)"
                    >
                      <span v-if="isQuote">Impression devis</span>
                      <span v-else>Impression justificatif</span>
                    </b-form-checkbox>
                  </div>
                  <div v-else>
                    <a
                      v-if="canChange && !inscription.cancelled"
                      href
                      class="btn btn-secondary btn-xs"
                      @click.prevent="onEdit(inscription)"
                    >
                      <i class="fa fa-edit"></i> Modifier
                    </a>
                    &nbsp;
                    <activity-cancel-button
                      :youth-home="youthHome"
                      :inscription="inscription"
                      :entity="entity"
                      @done="refresh()"
                      v-if="!inscription.cancelled"
                    >
                    </activity-cancel-button>
                  </div>
                </b-col>
              </b-row>
              <div v-if="isPortalActive && !inscription.cancelled">
                <b-row>
                  <b-col cols="10">
                    <div v-if="inscription.block" class="portal-blocked-message">
                      Le portail famille {{ inscription.activity.name }}
                      n'est pas accessible pour {{ inscription.individual.firstAndLastName() }}
                    </div>
                  </b-col>
                  <b-col class="text-right">
                    <a
                      v-if="canChange"
                      href
                      :class="inscription.block ? 'btn-warning' : 'btn-secondary'"
                      class="btn btn-xs"
                      @click.prevent="onBlock(inscription)"
                    >
                      <span v-if="inscription.block"><i class="fa fa-unlock"></i> Débloquer</span>
                      <span v-else><i class="fa fa-lock"></i> Bloquer l'accès portail</span>
                    </a>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showForm">
          <loading-gif :loading-name="entityActivitiesLoadingName"></loading-gif>
          <b-form @submit="onSave($event)" v-if="!isLoading(entityActivitiesLoadingName)">
            <div class="field-line">
              <b-row>
                <b-col md="2">
                  <b-form-group
                    id="schoolYearGroup"
                    label="Année"
                    label-for="schoolYear"
                  >
                    <b-form-select
                      id="schoolYear"
                      v-model="selectedSchoolYear"
                      required
                      :disabled="schoolYears.length <= 1"
                      v-if="!patchId"
                      >
                      <b-form-select-option
                        :value="schoolYear"
                        v-for="schoolYear in schoolYears"
                        v-bind:key="schoolYear.id"
                      >
                        {{ schoolYear.name }}
                      </b-form-select-option>
                    </b-form-select>
                    <b-form-input id="schoolYear" v-if="patchId" disabled :value="selectedSchoolYear.name">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="7">
                  <b-form-group
                    id="activityGroup"
                    label="Activité"
                    label-for="activity"
                  >
                    <b-form-select
                      id="activity"
                      v-model="selectedActivity"
                      required
                      v-if="!patchId"
                    >
                      <b-form-select-option
                        :value="activity"
                        v-for="activity in filteredActivities"
                        :key="activity.id"
                        :disabled="activity.disabled"
                      >
                        {{ activity.nameForInscription() }}
                      </b-form-select-option>
                    </b-form-select>
                    <div v-if="hasInherited">
                      <b-checkbox v-model="includeInherited">Inclure les ateliers</b-checkbox>
                    </div>
                    <b-form-input id="activity" v-if="patchId" disabled :value="selectedActivity.name">
                    </b-form-input>
                    <div v-if="!patchId && selectedActivity && inscriptionAlsoFor.length">
                      <check-box-select
                        :choices="inscriptionAlsoFor"
                        @changed="alsoForChanged($event.choices)"
                        :initial-value="inscriptionAlsoFor"
                        inline
                      ></check-box-select>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="selectedActivity && selectedActivity.id && !patchId">
                <b-col>
                  <b-form-group
                    id="individualGroup"
                    label="Membres"
                    label-for="individual"
                    v-if="entityIndividuals.length"
                  >
                    <check-box-select
                      :choices="entityIndividuals"
                      :initial-value="initIndividuals"
                      inline
                      id="individuals"
                      @changed="onIndividualsSelected($event.choices)"
                      @init="onIndividualsSelected($event.choices)"
                      :name-callback="individualName"
                    >
                    </check-box-select>
                  </b-form-group>
                  <div v-if="entityIndividuals.length === 0">
                    <b>Aucun membre de la famille n'est éligible à cette activité.</b>
                  </div>
                </b-col>
              </b-row>
              <div>
                <div v-for="ins of existingInscriptions" :key="ins.id" class="new-individual-inscriptions">
                  <b-row>
                    <b-col>{{ ins.individual.firstAndLastName()}}</b-col>
                    <b-col>{{ ins.activity.name }}</b-col>
                    <b-col class="text-right"><div class="badge badge-warning">déjà inscrit</div> </b-col>
                  </b-row>
                </div>
                <div v-for="ins of newInscriptions" :key="ins.id" class="new-individual-inscriptions">
                  <div v-if="showMemberAdhesions" class="text-right">
                    <span v-if="memberAdhesions(ins.individual, selectedSchoolYear).length === 0">
                      <span class="badge badge-danger">Aucune adhésion</span>
                    </span>
                    <span
                      v-for="adhesion of  memberAdhesions(ins.individual, selectedSchoolYear)"
                      :key="adhesion.id"
                      class="badge badge-light"
                    >
                      {{ adhesion.adhesionType.name }} - {{ adhesion.schoolYear.name }}
                    </span>
                  </div>
                  <b-row>
                    <b-col md="4">
                      <b-form-group
                        :id="'individual-label' + ins.id"
                        :label-for="'individual' + ins.id"
                        :description="ins.activity.name"
                      >
                        <b-input
                          :id="'individual' + ins.id"
                          type="text"
                          readonly
                          :value="ins.individual.firstAndLastName()"
                          class="small-input"
                        >
                        </b-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="5">
                      <b-form-group
                        :label-for="'price' + ins.id"
                        v-if="!ins.activity.isEvent()"
                      >
                        <b-form-select
                          :id="'price' + ins.id"
                          v-model="ins.selectedPrice"
                          required
                          :disabled="ins.activity.getPrices().length <= 1"
                          v-if="!patchId && !ins.activity.isFreePrice()"
                          class="small-select"
                        >
                          <b-form-select-option
                            :value="ins.selectedPrice"
                            v-if="ins.activity.getPrices().length === 0"
                          >
                            Gratuit
                          </b-form-select-option>
                          <b-form-select-option
                            :value="price"
                            v-for="price in ins.activity.getPrices()"
                            :key="price.index"
                          >
                            {{ getPriceText(price) }}
                          </b-form-select-option>
                        </b-form-select>
                        <decimal-input
                          :id="'price' + ins.id"
                          v-else-if="!patchId && ins.activity.isFreePrice()"
                          v-model="ins.freePrice"
                          :min="ins.activity.minPrice || 0"
                          :max="ins.activity.maxPrice"
                        >
                        </decimal-input>
                        <div v-if="!patchId && ins.activity.isFreePrice() && freePriceError(ins)" class="free-price-error">
                          {{ freePriceError(ins) }}
                          <a href @click.prevent="fixFreePriceError(ins)">Corriger</a>
                        </div>
                        <div v-if="!patchId && ins.selectedPrice && ins.selectedPrice.warning" class="price-error">
                          {{ ins.selectedPrice.warning }}
                        </div>
                        <div v-if="patchId">
                          <b-form-input
                            type="text"
                            :id="'price' + ins.id"
                            :value="ins.inscriptionPrice | currency"
                            disabled
                          >
                          </b-form-input>
                          <div class="discounts" v-if="ins.discounts && ins.discounts.length">
                            <div class="badge badge-dark">{{ ins.basePrice | currency }}</div>&nbsp;
                            <div class="badge badge-light" v-for="discount in ins.discounts" :key="discount.id">
                              <span v-if="discount.amount">-{{ discount.amount }}€</span>
                              <span v-if="discount.percentage">-{{ discount.percentage }}%</span>
                              <span v-if="discount.comments" v-b-tooltip="discount.comments">
                                &nbsp;<i class="fa fa-info-circle"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </b-form-group>
                      <div v-if="ins.activity.isEvent()">
                        <b-row>
                          <b-col><b>Prix</b></b-col>
                          <b-col class="text-right"><b>Nb. de places</b></b-col>
                        </b-row>
                        <b-row v-for="price in ins.activity.getPrices()" :key="price.index">
                          <b-col cols="8">
                            <b-form-input
                              type="text"
                              disabled
                              :id="'price' + price.index"
                              :name="'price' + price.index"
                              :value="getPriceText(price)"
                            ></b-form-input>
                          </b-col>
                          <b-col>
                            <number-input
                              min="0"
                              step="1"
                              :arrows="true"
                              :id="'seats' + price.index"
                              v-model="ins.seats[price.index]"
                              :disabled="seatsDisabled"
                            >
                            </number-input>
                          </b-col>
                        </b-row>
                        <div class="total-price">
                          <b-row>
                            <b-col><b>Total</b></b-col>
                            <b-col class="text-right"><b>{{ totalEventPrice(ins) | currency }}</b></b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-col>
                    <b-col v-if="youthHome" md="3">
                      <b-form-group
                        :label-for="'analytic' + ins.id"
                        description="Compte analytique utilisé pour les ventes des inscriptions aux séances"
                      >
                        <b-form-select
                          :id="'analytic' + ins.id"
                          v-model="ins.selectedAnalyticAccountId"
                          :disabled="!(ins.activity && ins.activity.analyticAccounts.length > 1)"
                        >
                          <b-form-select-option
                            :value="analyticAccount.id"
                            v-for="analyticAccount in ins.activity.analyticAccounts"
                            :key="analyticAccount.id"
                          >
                            {{ analyticAccount.name }}
                          </b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                      <div class="analytic-error" v-if="analyticAccountError(ins)">{{ analyticAccountError(ins) }}</div>
                    </b-col>
                    <b-col v-if="!youthHome" md="3">
                      <b-row>
                        <b-col md="8" class="text-right">
                          <b-form-group
                            :label-for="'trying' + ins.id"
                          >
                            <b-form-checkbox
                              :id="'trying' + ins.id"
                              v-model="ins.trying"
                              :disabled="invoiceDone || ins.waiting"
                            >
                              À l'essai
                            </b-form-checkbox>
                            <div class="field-description">
                              Une fois facturée, l'inscription <br />
                              ne peut plus être à l'essai
                            </div>
                          </b-form-group>
                          <div v-if="ins.trying">
                            <b-input type="date" v-model="ins.tryingDate" class="small-input"></b-input>
                            <div class="field-description">
                              Date limite d'essai
                            </div>
                          </div>
                        </b-col>
                        <b-col md="4" class="text-right">
                          <b-form-group
                            label=""
                            :label-for="'waiting' + ins.id"
                          >
                            <b-form-checkbox
                              :id="'waiting' + ins.id"
                              v-model="ins.waiting"
                              :disabled="invoiceDone || ins.trying"
                            >
                              Attente
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="patchId">
                        <b-col md="12" class="text-right">
                          <b-form-group
                            :label-for="'hasLeftOn' + ins.id"
                          >
                            <b-form-checkbox
                              :id="'hasLeftOn' + ins.id"
                              :checked="!!ins.hasLeftOn"
                              @change="ins.hasLeftOn = new Date()"
                            >
                              A quitté
                            </b-form-checkbox>
                            <div class="field-description">
                              Le membre ne sera plus contacté et <br />
                              apparaitra distinctement sur les listings
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="activity-conditions" v-if="ins.activity.conditions">
                        {{ ins.activity.conditions }}
                      </div>
                      <a href class="small-link" @click.prevent="ins.hasComments = !ins.hasComments">
                        <span v-if="!ins.hasComments">Ajouter un commentaire</span>
                        <span v-else>Supprimer le commentaire</span>
                      </a>
                    </b-col>
                    <b-col md="3" class="text-right">
                      <div class="activity-inscriptions-count-badge-holder">
                        <activity-inscriptions-count-badge :activity="ins.activity" @max-limit="onMaxLimit">
                        </activity-inscriptions-count-badge>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Commentaires"
                        :label-for="'comments' +  ins.id"
                         v-if="ins.hasComments"
                      >
                        <b-form-input
                          :id="'comments' + ins.id"
                          v-model="ins.comments"
                          type="text"
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="!patchId">
                    <b-col>
                      <hr />
                      <discount-field
                        :price="ins.selectedPrice ? ins.selectedPrice.price : 0"
                        :discounts="ins.discounts"
                        :extra-sales="ins.extraSales"
                        @discounts-changed="discountsChanged(ins, $event)"
                      >
                      </discount-field>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <b-row class="buttons-bar">
              <b-col class="text-right">
                <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">
                  Annuler
                </a>
                <b-button type="submit" variant="primary" :disabled="!isSaveValid()">
                  <span v-if="patchId">Enregistrer</span>
                  <span v-else>Ajouter</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
    </div>
    <confirm-modal
      :name="blockInscriptionModalName"
      :title="blockInscriptionModalTitle"
      :icon="blockInscriptionModalIcon"
      :text="blockInscriptionModalText"
      :object="blockInscription"
      @confirmed="onConfirmBlockInscription"
    ></confirm-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import ActivityCancelButton from '@/components/Activities/ActivityCancelButton'
import ActivityExtraSaleButton from '@/components/Activities/ActivityExtraSaleButton'
import DiscountField from '@/components/Discounts/DiscountField.vue'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import DecimalInput from '@/components/Controls/DecimalInput'
import LoadingGif from '@/components/Controls/LoadingGif'
import SalePrice from '@/components/Invoices/SalePrice'
import TabsBar from '@/components/Controls/TabsBar'
import ActivityInscriptionsCountBadge from '@/components/Activities/ActivityInscriptionsCountBadge'
import NumberInput from '@/components/Controls/NumberInput'
import CounterLabel from '@/components/Controls/CounterLabel'
import { currency } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeActivity, makeActivityInscription } from '@/types/activities'
import { TabItem } from '@/types/tabs'
import { BackendApi, openDocument } from '@/utils/http'
import { compareNumbers, compareStrings } from '@/utils/sorting'
import { makeChoice } from '@/types/base'
import { existsIn } from '@/utils/arrays'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'

export default {
  name: 'entity-activities',
  components: {
    ConfirmModal,
    CheckBoxSelect,
    CounterLabel,
    NumberInput,
    ActivityInscriptionsCountBadge,
    ActivityCancelButton,
    ActivityExtraSaleButton,
    DiscountField,
    SalePrice,
    InvoiceBadge,
    TabsBar,
    LoadingGif,
    DecimalInput,
  },
  mixins: [BackendMixin],
  props: {
    showAll: Boolean,
    youthHome: Number,
    title: String,
    entity: Object,
    allowEdit: {
      type: Boolean,
      default: false,
    },
    addForActivity: {
      type: Object,
      default: null,
    },
    adhesions: {
      type: Array,
    },
    category: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editModeEnabled: true,
      inscriptions: [],
      activities: [],
      selectedSchoolYear: null,
      schoolYears: [],
      selectedActivity: null,
      selectedIndividuals: [],
      initIndividuals: [],
      activeTab: null,
      patchId: 0,
      invoiceDone: false,
      tabs: [
        new TabItem('actives', 'En cours', 'far fa-clock'),
        new TabItem('all', 'Historique', 'fa fa-history')
      ],
      entityActivitiesLoadingName: 'entity-activities',
      showPrintJustificative: false,
      isQuote: false,
      includeAdhesionInJustificative: false,
      selectedInscriptions: [],
      alsoFor: [],
      newInscriptions: [],
      existingInscriptions: [],
      displayModes: [
        makeChoice({ id: 1, name: 'Inscriptions', }),
        makeChoice({ id: 2, name: 'Annulations', }),
        makeChoice({ id: 3, name: 'Toutes', })
      ],
      selectedDisplayMode: null,
      includeInherited: false,
      blockInscription: null,
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    isPortalActive() {
      return this.youthHome && store.getters.config.isPortalActive
    },
    showForm() {
      return (store.getters.editMode === 'inscriptions')
    },
    editDisabled() {
      if (this.allowEdit) {
        return false
      }
      return (store.getters.editMode !== '' && store.getters.editMode !== 'inscriptions')
    },
    hasInherited() {
      return !this.addForActivity && (this.schoolYearActivities.filter(elt => elt.inheritFrom).length > 0)
    },
    schoolYearActivities() {
      if (this.selectedSchoolYear) {
        let activities = this.activities.filter(
          elt => { return elt.schoolYear.id === this.selectedSchoolYear.id }
        )
        if (!this.youthHome) {
          activities = activities.sort(
            (act1, act2) => {
              const num1 = act1.disabled ? 1 : -1
              const num2 = act2.disabled ? 1 : -1
              if (num1 === num2) {
                return compareStrings(act1.name, act2.name)
              } else {
                return num1
              }
            }
          )
        }
        return activities
      }
      return []
    },
    filteredActivities() {
      if (this.addForActivity === null) {
        return this.schoolYearActivities.filter(
          elt => ((this.includeInherited) || (elt.inheritFrom === null))
        )
      } else {
        return this.activities.filter(elt => elt.id === this.addForActivity.id)
      }
    },
    inscriptionAlsoFor() {
      let alsoFor = []
      if (this.selectedActivity) {
        if (this.selectedActivity.inscriptionAlsoFor.length) {
          alsoFor = this.activities.filter(
            elt => {
              return existsIn([elt.id], this.selectedActivity.inscriptionAlsoFor)
            }
          )
        } else {
          let parentId = null
          if (this.selectedActivity.inheritFrom) {
            parentId = this.selectedActivity.inheritFrom.id
          }
          alsoFor = this.activities.filter(
            elt => (
              elt.id !== this.selectedActivity.id && !elt.disabled &&
              (
                (elt.inheritFrom && (elt.inheritFrom.id === this.selectedActivity.id)) ||
                (parentId && ((parentId === elt.id) || (elt.inheritFrom && elt.inheritFrom.id === parentId)))
              )
            )
          )
        }
      }
      return alsoFor.map(
        elt => makeChoice({ id: elt.id, name: elt.name, })
      )
    },
    disabledAlsoFor() {
      return this.inscriptionAlsoFor.filter(
        elt => (
          elt.disabled ||
          (this.selectedIndividual === null) ||
          this.hasInscription(elt, this.selectedIndividual)
        )
      )
    },
    extraSelectedActivities() {
      if (this.selectedActivity && this.inscriptionAlsoFor.length) {
        const alsoForIds = this.alsoFor.map(elt => elt.id)
        return this.activities.filter(
          elt => existsIn([elt.id], alsoForIds)
        )
      }
      return []
    },
    entityIndividuals() {
      let individuals = []
      if (this.youthHome) {
        if (this.selectedActivity) {
          if (!this.selectedActivity.allowAdults()) {
            individuals = this.entity.getChildrenIndividuals()
          } else {
            individuals = this.entity.getMainIndividuals()
          }
          if (this.selectedActivity.yearsForInscriptions.length) {
            individuals = individuals.filter(
              ins => existsIn([ins.birthYear()], this.selectedActivity.yearsForInscriptions)
            )
          }
        }
      } else {
        if (this.selectedActivity.allowAllMembers) {
          individuals = this.entity.getAllIndividuals()
        } else {
          individuals = this.entity.getMainIndividuals()
        }
      }
      return individuals
    },
    individualsWithInscriptions() {
      return this.entityIndividuals.filter(
        individual => this.hasInscription(this.selectedActivity, individual)
      ).map(elt => elt.id)
    },
    canView() {
      if (this.youthHome) {
        return this.hasPerm('youth.view_youthhomeactivityinscription')
      } else {
        return this.hasPerm('activities.view_coreactivityinscription')
      }
    },
    canChange() {
      if (this.youthHome) {
        return this.hasPerm('youth.add_youthhomeactivityinscription')
      } else {
        return this.hasPerm('activities.add_coreactivityinscription')
      }
    },
    hasFreePriceError() {
      if (!this.patchId) {
        for (const ins of this.newInscriptions) {
          const error = this.freePriceError(ins)
          if (error) {
            return true
          }
        }
      }
      return false
    },
    hasPriceError() {
      if (!this.patchId) {
        for (const ins of this.newInscriptions) {
          const error = this.priceError(ins)
          if (error) {
            return true
          }
        }
      }
      return false
    },
    selectionError() {
      return !(
        this.selectedActivity && this.selectedActivity.id && this.newInscriptions.length
      )
    },
    hasAnalyticAccountError() {
      if (!this.selectionError) {
        for (const ins of this.newInscriptions) {
          const error = this.analyticAccountError(ins)
          if (error) {
            return true
          }
        }
      }
      return ''
    },
    showMemberAdhesions() {
      return this.hasPerm('adhesions.view_adhesion')
    },
    seatsDisabled() {
      return !!(this.patchId && +this.patchId)
    },
    filteredInscriptions() {
      if (this.inscriptions) {
        if (this.selectedDisplayMode.id === 1) {
          return this.inscriptions.filter(
            elt => !elt.cancelled
          )
        }
        if (this.selectedDisplayMode.id === 2) {
          return this.inscriptions.filter(
            elt => elt.cancelled
          )
        }
        return this.inscriptions
      }
      return []
    },
    blockInscriptionModalName() {
      return 'block-inscription'
    },
    blockInscriptionModalTitle() {
      if (this.blockInscription) {
        if (this.blockInscription.block) {
          return 'Débloquer l\'accès au portail famille'
        } else {
          return 'Bloquer l\'accès au portail famille'
        }
      }
      return ''
    },
    blockInscriptionModalIcon() {
      if (this.blockInscription) {
        if (this.blockInscription.block) {
          return 'fa fa-unlock'
        } else {
          return 'fa fa-lock'
        }
      }
      return ''
    },
    blockInscriptionModalText() {
      let text = ''
      if (this.blockInscription) {
        text = this.blockInscription.activity.name + ' pour '
        text += this.blockInscription.individual.firstAndLastName()
      }
      return text
    },
  },
  created() {
    this.selectedActivity = makeActivity()
    this.tabChanged(this.tabs[0])
    this.selectedDisplayMode = this.displayModes[0]
  },
  watch: {
    includeInherited: function() {
      if (!this.includeInherited && this.selectedActivity && this.selectedActivity.inheritFrom) {
        let zeroActivities = this.activities.filter(elt => elt.id === 0)
        if (zeroActivities.length) {
          this.selectedActivity = zeroActivities[0]
        } else {
          this.selectedActivity = makeActivity()
        }
      }
    },
    selectedActivity: function(newValue) {
      if (this.selectedActivity) {
        for (const ins of this.newInscriptions) {
          let prices = this.selectedActivity.getPrices()
          if (prices.length === 1) {
            ins.selectedPrice = prices[0]
          } else {
            ins.selectedPrice = null
          }
          if (this.selectedActivity.isFreePrice()) {
            ins.freePrice = newValue.minPrice || 0
          }
          ins.selectedAnalyticAccountId = 0
          if (this.selectedActivity.analyticAccounts.length === 1) {
            // Auto-select if only 1 choice
            ins.selectedAnalyticAccountId = this.selectedActivity.analyticAccounts[0].id
          }
        }
        if (
          (this.entityIndividuals.length === 1) &&
          (!this.hasInscription(this.selectedActivity, this.entityIndividuals[0]))
        ) {
          this.initIndividuals = this.entityIndividuals
          this.onIndividualsSelected(this.initIndividuals)
        } else {
          this.onIndividualsSelected(this.selectedIndividuals)
        }
      }
      this.recalcInscriptions()
    },
    inscriptionAlsoFor: function() {
      this.alsoFor = this.inscriptionAlsoFor
    },
    isYouthHome: function() {
      // reinitialize the component
      this.selectedActivity = makeActivity()
      this.tabChanged(this.tabs[0])
    },
    youthHome: function() {
      this.selectedActivity = makeActivity()
      this.tabChanged(this.tabs[0])
    },
    entity: function() {
      this.tabChanged(this.tabs[0])
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    showPrintDoc(quote) {
      this.showPrintJustificative = true
      this.isQuote = quote
    },
    async onAdd() {
      if (!this.editDisabled) {
        this.patchId = 0
        this.newInscriptions = []
        this.invoiceDone = false
        this.setEditMode('inscriptions')
        await this.loadActivities()
      }
    },
    async onEdit(inscription) {
      if (!this.editDisabled) {
        await this.loadActivities()
        this.patchId = inscription.id
        this.invoiceDone = inscription.invoice !== null
        this.selectedSchoolYear = inscription.activity.schoolYear
        // réaffecte avec un elements de la liste des activités pour avoir l'objet complet
        // avec la liste des codes analytiques
        const activityIds = this.activities.map(elt => elt.id)
        const selectedActivityIndex = activityIds.indexOf(inscription.activity.id)
        if (selectedActivityIndex < 0) {
          // fallback : si l'activité de l'inscription n'est plus dans la liste
          this.selectedActivity = inscription.activity
        } else {
          this.selectedActivity = this.activities[selectedActivityIndex]
        }
        this.selectedIndividuals = []
        let tryingDate = null
        if (inscription.trying && inscription.tryingDate) {
          tryingDate = moment(inscription.tryingDate).format('YYYY-MM-DD')
        }
        const ins = {
          id: inscription.id,
          activity: this.selectedActivity,
          individual: inscription.individual,
          waiting: inscription.waiting,
          trying: inscription.trying,
          tryingDate: tryingDate,
          comments: inscription.comments,
          hasComments: !!inscription.comments,
          inscriptionPrice: inscription.getInscriptionPrice(),
          basePrice: inscription.price,
          discounts: inscription.discounts.map(elt => elt.clone()),
          extraSales: [],
          seats: [],
          selectedAnalyticAccountId: inscription.activityAnalyticAccount ? inscription.activityAnalyticAccount.id : 0,
        }
        for (const elt of inscription.seats) {
          ins.seats[elt.index] = elt.seats
        }
        ins.seats = [].concat(ins.seats)
        let prices = ins.activity.getPrices()
        if (prices.length === 1) {
          ins.selectedPrice = prices[0]
        } else {
          ins.selectedPrice = null
        }
        if (this.selectedActivity.isFreePrice()) {
          ins.freePrice = this.selectedActivity.minPrice || 0
        }
        if (this.selectedActivity.analyticAccounts.length === 1) {
          // Auto-select if only 1 choice
          ins.selectedAnalyticAccountId = this.selectedActivity.analyticAccounts[0].id
        }
        this.newInscriptions = [ins]
        this.setEditMode('inscriptions')
      }
    },
    onBlock(inscription) {
      this.blockInscription = inscription
      this.$bvModal.show('bv-confirm-modal:' + this.blockInscriptionModalName)
    },
    async onConfirmBlockInscription() {
      if (this.blockInscription) {
        const data = {
          enable: !this.blockInscription.block,
        }
        let url = '/api/youth/block-activity-inscription/' + this.blockInscription.id + '/'
        let backendApi = new BackendApi('post', url)
        try {
          let resp = await backendApi.callApi(data)
          const inscription = makeActivityInscription(resp.data)
          let index = this.inscriptions.map(elt => elt.id).indexOf(this.blockInscription.id)
          if (index >= 0) {
            this.inscriptions[index] = inscription
          }
          this.inscriptions = [].concat(this.inscriptions)
          let text = ''
          if (inscription.block) {
            text = 'Le portail famille ' + inscription.activity.name + ' est bloqué pour '
            text += inscription.individual.firstAndLastName()
          } else {
            text = 'Le portail famille ' + inscription.activity.name + ' est débloqué pour '
            text += inscription.individual.firstAndLastName()
          }
          await this.addSuccess(text)
          this.blockInscription = null
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onCancel() {
      this.setEditMode('')
      this.reset()
      if (!this.entity.id) {
        this.getBack()
      }
    },
    reset() {
      this.selectedActivity = makeActivity()
      this.selectedIndividuals = []
      this.alsoFor = []
    },
    async patch() {
      if (this.newInscriptions.length) {
        const ins = this.newInscriptions[0]
        let tryingDate = null
        if (ins.trying && ins.tryingDate) {
          tryingDate = moment(ins.tryingDate).format('YYYY-MM-DD')
        }
        const data = {
          trying: ins.trying,
          trying_date: tryingDate,
          waiting: ins.waiting,
          comments: ins.comments,
          has_left: !!ins.hasLeftOn,
          activity_analytic_account: ins.selectedAnalyticAccountId,
        }
        let url = '/api/entity/' + this.entity.id + '/inscriptions/' + this.patchId + '/'
        if (this.youthHome) {
          url = '/api/entity/' + this.entity.id + '/youth/activity-inscriptions/' + this.patchId + '/'
        }
        let backendApi = new BackendApi('patch', url)
        try {
          let resp = await backendApi.callApi(data)
          const inscription = makeActivityInscription(resp.data)
          let index = this.inscriptions.map(elt => elt.id).indexOf(this.patchId)
          if (index >= 0) {
            this.inscriptions[index] = inscription
          }
          this.inscriptions = [].concat(this.inscriptions)
          this.$emit('saved', { inscription: inscription, })
          await this.addSuccess('L\'inscription a été enregistrée')
          this.reset()
          this.setEditMode('')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async onSave(evt) {
      evt.preventDefault()
      if (this.patchId) {
        await this.patch()
      } else {
        await this.post()
      }
    },
    async post() {
      let backendApi = null
      let data = []
      let url = '/api/entity/' + this.entity.id + '/inscriptions/'
      if (this.youthHome) {
        url = '/api/entity/' + this.entity.id + '/youth/activity-inscriptions/'
      }
      backendApi = new BackendApi('post', url)
      for (const ins of this.newInscriptions) {
        let price = 0
        let selectedPrice = null
        let seats = null
        if (ins.activity.isEvent()) {
          seats = []
          for (const elt of ins.activity.getPrices()) {
            const seatsNumber = ins.seats[elt.index] || 0
            if (seatsNumber) {
              price += seatsNumber * elt.price
              seats.push(
                { name: elt.name, price: elt.price, seats: seatsNumber, index: elt.index, }
              )
            }
          }
        } else {
          price = ins.selectedPrice ? ins.selectedPrice.price : 0
          if (ins.activity.isFlatPrice()) {
            selectedPrice = ins.selectedPrice ? ins.selectedPrice.id : null
          }
          if (ins.activity.isFreePrice()) {
            price = ins.freePrice
          }
        }
        let tryingDate = null
        if (ins.trying && ins.tryingDate) {
          tryingDate = moment(ins.tryingDate).format('YYYY-MM-DD')
        }
        const dataItem = {
          activity: ins.activity.id,
          individual: ins.individual.id,
          trying: ins.trying,
          trying_date: tryingDate,
          waiting: ins.waiting,
          price: Math.round(+price * 100) / 100,
          comments: ins.hasComments ? ins.comments : '',
          discounts: ins.discounts.map(elt => elt.data()),
          extra_sales: ins.extraSales.map(elt => elt.data()),
          activity_analytic_account: ins.selectedAnalyticAccountId,
          selected_price: selectedPrice,
        }
        if (seats) {
          dataItem.seats = seats
        }
        data.push(dataItem)
      }
      this.startLoading(this.entityActivitiesLoadingName)
      const savedInscriptions = []
      try {
        for (const dataItem of data) {
          let resp = await backendApi.callApi(dataItem)
          const inscription = makeActivityInscription(resp.data)
          this.inscriptions = [inscription].concat(this.inscriptions)
          savedInscriptions.push(inscription)
        }
        for (const inscription in savedInscriptions) {
          this.$emit('saved', { inscription: inscription, })
        }
        await this.addSuccess('L\'inscription a été enregistrée')
        this.reset()
        this.setEditMode('')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.entityActivitiesLoadingName)
    },
    async loadInscriptions(all = false) {
      if ((this.entity) && (this.entity.id > 0)) {
        this.startLoading(this.entityActivitiesLoadingName)
        let url = '/api/entity/' + this.entity.id + '/inscriptions/?all=' + (all ? '1' : '0')
        url += '&category=' + this.category
        if (this.youthHome) {
          url = '/api/entity/' + this.entity.id + '/youth/activity-inscriptions/?all=' + (all ? '1' : '0')
        }
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.inscriptions = resp.data.map(elt => { return makeActivityInscription(elt) })
          this.selectedInscriptions = this.inscriptions.filter(elt => !elt.cancelled).map(elt => elt.id)
          this.setEditMode('')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.entityActivitiesLoadingName)
      }
    },
    async loadActivities() {
      this.startLoading(this.entityActivitiesLoadingName)
      this.activities = []
      this.schoolYears = []
      let schoolYearIds = []
      let url = '/api/entity/' + this.entity.id + '/activities/?category=' + this.category
      if (this.youthHome) {
        url = '/api/entity/' + this.entity.id + '/youth/activities/'
      }
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.activities = resp.data.map(elt => makeActivity(elt))
        for (let activity of this.activities) {
          if (schoolYearIds.indexOf(activity.schoolYear.id) < 0) {
            schoolYearIds.push(activity.schoolYear.id)
            this.schoolYears.push(activity.schoolYear)
          }
        }
        this.schoolYears = this.schoolYears.sort(
          (year1, year2) => {
            return -compareNumbers(year1.startYear, year2.startYear)
          }
        )
        if (this.schoolYears.length > 0) {
          this.selectedSchoolYear = this.schoolYears[0]
        }
        if (this.filteredActivities.length === 1) {
          this.selectedActivity = this.filteredActivities[0]
          if (this.addForActivity) { // l'activité est pré-sélectionnée.
            this.schoolYears = [this.selectedActivity.schoolYear]
            this.selectedSchoolYear = this.schoolYears[0]
          }
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.entityActivitiesLoadingName)
    },
    async tabChanged(tab) {
      this.activeTab = tab
      let showAll = (this.activeTab.name === 'all')
      await this.loadInscriptions(showAll)
    },
    async refresh() {
      let showAll = (this.activeTab.name === 'all')
      await this.loadInscriptions(showAll)
      this.$emit('saved', {})
    },
    getPriceText(price) {
      let priceText = '' + currency(price.price)
      if (price.name) {
        priceText = priceText + ' - ' + price.name
      }
      return priceText
    },
    discountsChanged(ins, evt) {
      ins.discounts = evt.discounts
    },
    hasInscription(activity, individual) {
      if (activity && individual) {
        if (activity.isEvent()) {
          return false // autorise plusieurs inscriptions aux événements
        }
        for (let inscription of this.inscriptions) {
          if (
            (inscription.activity.id === activity.id) &&
            (inscription.individual.id === individual.id) &&
            !inscription.cancelled
          ) {
            return true
          }
        }
      }
      return false
    },
    onMaxLimit(event) {
      if (event.maxLimit && !this.waiting) {
        this.waiting = event.maxLimit
      }
    },
    fixFreePriceError(ins) {
      if (this.selectedActivity.isFreePrice()) {
        const minPrice = +this.selectedActivity.minPrice
        const maxPrice = +this.selectedActivity.maxPrice
        const price = +ins.freePrice
        if (minPrice && (price < minPrice)) {
          ins.freePrice = minPrice
        }
        if (maxPrice && (price > maxPrice)) {
          ins.freePrice = maxPrice
        }
      }
    },
    isSaveValid() {
      if (this.selectionError) {
        return false
      }
      if (this.hasFreePriceError || this.hasPriceError) {
        return false
      }
      if (this.hasAnalyticAccountError) {
        return false
      }
      return true
    },
    async printJustificative() {
      if (this.selectedInscriptions.length) {
        const docUrl = '/justificatif-inscriptions-activites/pdf/<key>/'
        const ids = [
          this.entity.id,
          this.includeAdhesionInJustificative ? 1 : 0,
          this.isQuote ? 1 : 0
        ].concat(this.selectedInscriptions)
        const docSlug = '' + ids.map(elt => '' + elt).join('-')
        try {
          await openDocument(docUrl, docSlug)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.showPrintJustificative = false
      }
    },
    isInscriptionSelected(inscription) {
      return this.selectedInscriptions.indexOf(inscription.id) >= 0
    },
    selectInscription(inscription, value) {
      if (value) {
        this.selectedInscriptions.push(inscription.id)
      } else {
        const index = this.selectedInscriptions.indexOf(inscription.id)
        if (index >= 0) {
          this.selectedInscriptions.splice(index, 1)
        }
      }
      this.selectedInscriptions = [].concat(this.selectedInscriptions)
    },
    memberAdhesions(individual, schoolYear) {
      return this.adhesions.filter(
        elt => elt.schoolYear.id === schoolYear.id
      ).filter(
        elt => (
          (elt.adhesionType.applyToIndividual() && elt.individual && elt.individual.id === individual.id) ||
          (elt.adhesionType.applyToEntity())
        )
      )
    },
    freePriceError(ins) {
      if (this.selectedActivity.isFreePrice()) {
        const price = +ins.freePrice
        const minPrice = this.selectedActivity.minPrice
        const maxPrice = this.selectedActivity.maxPrice
        if (minPrice && (price < minPrice)) {
          return 'Prix inférieur au plancher de ' + currency(minPrice)
        }
        if (maxPrice && (price > maxPrice)) {
          return 'Prix supérieur au plafond de ' + currency(maxPrice)
        }
      }
      return ''
    },
    priceError(ins) {
      if (ins.activity.isEvent()) {
        let hasOneSeat = false
        for (const price of ins.activity.getPrices()) {
          const seats = ins.seats[price.index] || 0
          if (seats) {
            hasOneSeat = true
            break
          }
        }
        return !hasOneSeat
      } else if (!ins.activity.isFreePrice() && ins.activity.getPrices().length) {
        return ins.selectedPrice === null
      }
      return false
    },
    individualName(elt) {
      const suffix = this.hasInscription(this.selectedActivity, elt) ? ' (déjà inscrit)' : ''
      return elt.firstAndLastName() + suffix
    },
    recalcInscriptions() {
      if (this.selectedActivity && !this.patchId) {
        const allActivities = [this.selectedActivity].concat(this.extraSelectedActivities)
        let potentialInscriptions = []
        this.existingInscriptions = []
        for (const individual of this.selectedIndividuals) {
          for (const activity of allActivities) {
            if (!this.hasInscription(activity, individual)) {
              potentialInscriptions.push({ individual, activity, })
            } else {
              this.existingInscriptions.push({ individual, activity, id: this.existingInscriptions.length + 1, })
            }
          }
        }
        // supprime les individus qui ne sont plus cochées
        const keepThis = []
        for (let index = this.newInscriptions.length - 1; index >= 0; index--) {
          const ins = this.newInscriptions[index]
          const stillExists = potentialInscriptions.filter(
            elt => (
              (elt.individual.id === ins.individual.id) &&
              (elt.activity.id === ins.activity.id)
            )
          ).length
          if (!stillExists) {
            this.newInscriptions.splice(index, 1)
          } else {
            keepThis.push(ins.id)
          }
        }
        for (const potential of potentialInscriptions) {
          const insId = '' + potential.individual.id + '#' + potential.activity.id
          if (!existsIn([insId], keepThis)) {
            const ins = {
              id: insId,
              activity: potential.activity,
              individual: potential.individual,
              waiting: false,
              trying: false,
              tryingDate: null,
              comments: '',
              hasComments: false,
              discounts: [],
              extraSales: [],
              seats: [],
            }
            let prices = potential.activity.getPrices()
            if (prices.length === 1) {
              ins.selectedPrice = prices[0]
            } else {
              ins.selectedPrice = null
            }
            if (potential.activity.isFreePrice()) {
              ins.freePrice = potential.activity.minPrice || 0
            }
            if (potential.activity.analyticAccounts.length === 1) {
              // Auto-select if only 1 choice
              ins.selectedAnalyticAccountId = potential.activity.analyticAccounts[0].id
            }
            this.newInscriptions.push(ins)
          }
        }
      } else {
        // this.newInscriptions = []
        this.existingInscriptions = []
      }
    },
    onIndividualsSelected(selectedIndividuals) {
      if (!this.patchId) {
        this.selectedIndividuals = selectedIndividuals
        this.recalcInscriptions()
      }
    },
    alsoForChanged(alsoFor) {
      if (!this.patchId) {
        this.alsoFor = alsoFor
        this.recalcInscriptions()
      }
    },
    analyticAccountError(ins) {
      if (!this.selectionError) {
        if (this.youthHome && ins.activity) {
          if (ins.activity.analyticAccounts.length === 0) {
            return 'Erreur de configuration: Aucun compte analytique n\'est associé à ' + this.selectedActivity.name
          }
          if (!ins.selectedAnalyticAccountId) {
            return 'Vous devez saisir un compte analytique'
          }
        }
      }
      return ''
    },
    totalEventPrice(ins) {
      let total = 0
      if (ins.activity && ins.activity.isEvent()) {
        for (const price of ins.activity.getPrices()) {
          const seats = ins.seats[price.index] || 0
          if (seats) {
            total += seats * price.price
          }
        }
      }
      return total
    },
    getEntityLink(entity) {
      return {
        name: 'families-detail',
        params: { entityId: '' + entity.id, },
        query: { tab: 'activities', },
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .small-col {
    padding: 0;
  }
  .activity-conditions {
    margin: 2px 0px;
    padding: 5px;
    font-size: 11px;
    background: #f88686;
    color: #000;
    font-weight: bold;
  }
  .free-price-error {
    display: block;
    font-weight: bold;
    padding: 2px;
    margin: 5px 0;
    background: #ccc;
  }
  .price-error {
    display: block;
    font-weight: bold;
    padding: 2px;
    margin: 5px 0;
    background: #ffca22;
  }
  .field-group-header .text-right .btn {
    margin-left: 2px;
  }
  .analytic-error {
    font-size: 12px;
    color: #cc0000;
  }
  .total-price {
    padding: 0 10px;
    margin: 10px 0;
    border: solid 1px #000;
  }
  .seats {
    background: #444;
    color: #fff;
    padding: 1px 4px;
    margin-right: 2px;
    margin-bottom: 2px;
  }
  .badges-col .badge{
    margin-right: 3px;
  }
  .new-individual-inscriptions {
    border: solid 1px #ccc;
    background: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
  }
  a.small-link {
    font-size: 12px;
    color: #444 !important;
  }
  .portal-blocked-message {
    background: #fbff8d;
    padding: 4px 10px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 14px;
  }
</style>
