<template>
  <div>
    <div class="text-right" v-if="!readOnly" style="margin-bottom: 2px;">
      <a v-if="!patchId" href @click.prevent="addExtra" class="btn btn-secondary btn-sm">
        Ajouter un supplément
      </a>
      &nbsp;
      <a href @click.prevent="addDiscount" class="btn btn-secondary btn-sm">
        Ajouter une réduction
      </a>
    </div>
    <div v-for="discount in allDiscounts" :key="discount.id">
      <div class="field-line">
        <b-row>
          <b-col cols="3">
            <div class="discount-label">
              Réduction
            </div>
            <b-checkbox
              :id="'discount-type' + discount.id"
              v-model="discount.showPercentage"
              :value="true"
              :unchecked-value="false"
              :disabled="readOnly"
            >
              En pourcentage
            </b-checkbox>
          </b-col>
          <b-col cols="2">
            <b-form-group
              :id="'discountAmountGroup' + discount.id"
              label="en €"
              :label-for="'discountAmount' + discount.id"
              v-if="!discount.showPercentage"
            >
              <decimal-input
                :id="'discountAmount' + discount.id"
                v-model="discount.amount"
                :disabled="readOnly"
                @change="discountsChanged"
              >
              </decimal-input>
            </b-form-group>
            <b-form-group
              :id="'discountPercentageGroup' + discount.id"
              label="en %"
              :label-for="'discountPercentage' + discount.id"
              v-if="discount.showPercentage"
            >
              <b-form-input
                :id="'discountPercentage' + discount.id"
                v-model="discount.percentage"
                type="number"
                step="0.01"
                :disabled="readOnly"
                @change="discountsChanged"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group
              :id="'discountCommentsGroup' + discount.id"
              label="Commentaires"
              :label-for="'discountComments' + discount.id"
              @change="discountsChanged"
            >
              <b-form-input
                :id="'discountComments' + discount.id"
                v-model="discount.comments"
                :disabled="readOnly"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <div class="text-right" v-if="!readOnly">
              <br />
              <a class="btn btn-secondary  btn-sm" href @click.prevent="removeDiscount(discount)">
                Supprimer
              </a>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-for="sale in extraSales" :key="sale.id">
      <div class="field-line">
        <b-row>
          <b-col cols="3">
            <b-form-group
              :id="'percentageAmount' + sale.id"
              label="Supplément en %"
              :label-for="'percentageAmount' + sale.id"
            >
              <decimal-input
                :id="'percentageAmount' + sale.id"
                :value="getPercentage(sale)"
                :disabled="readOnly"
                @focusout="percentageAmountChanged($event, sale)"
              >
              </decimal-input>
            </b-form-group>

          </b-col>
          <b-col cols="2">
            <b-form-group
              :id="'saleAmountGroup' + sale.id"
              label="ou en €"
              :label-for="'saleAmount' + sale.id"
            >
              <decimal-input
                :id="'saleAmount' + sale.id"
                v-model="sale.amount"
                :disabled="readOnly"
                @change="discountsChanged"
              >
              </decimal-input>
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group
              :id="'saleCommentsGroup' + sale.id"
              label="Commentaires"
              :label-for="'saleComments' + sale.id"
              @change="discountsChanged"
            >
              <b-form-input
                :id="'saleComments' + sale.id"
                v-model="sale.comments"
                :disabled="readOnly"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <div class="text-right" v-if="!readOnly">
              <br />
              <a class="btn btn-secondary  btn-sm" href @click.prevent="removeExtra(sale)">
                Supprimer
              </a>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import { makeDiscount, makeExtraSale } from '@/types/payments'

export default {
  name: 'DiscountField',
  components: { DecimalInput, },
  props: {
    price: [Number, String],
    discounts: Array,
    readOnly: {
      type: Boolean,
      defaultValue: false,
    },
    label: {
      type: String,
      defaultValue: '',
    },
    extraSales: Array,
    patchId: Number,
  },
  data() {
    return {
      addedCount: 0,
    }
  },
  computed: {
    allDiscounts() {
      return this.discounts
    },
  },
  watch: {
    discounts: function() {},
    extraSales: function() {},
    readOnly: function() {},
  },
  methods: {
    discountsChanged() {
      this.$emit(
        'discounts-changed',
        { discounts: this.discounts, extraSales: this.extraSales, }
      )
    },
    addDiscount() {
      let discount = makeDiscount()
      this.addedCount++
      discount.id = -this.addedCount
      this.discounts.push(discount)
      this.discountsChanged()
    },
    addExtra() {
      let sale = makeExtraSale()
      this.addedCount++
      sale.id = -this.addedCount
      this.extraSales.push(sale)
      this.discountsChanged()
    },
    removeDiscount(discount) {
      let index = this.discounts.map(elt => elt.id).indexOf(discount.id)
      if (index >= 0) {
        this.discounts.splice(index, 1)
        this.discountsChanged()
      }
    },
    removeExtra(sale) {
      let index = this.extraSales.map(elt => elt.id).indexOf(sale.id)
      if (index >= 0) {
        this.extraSales.splice(index, 1)
        this.discountsChanged()
      }
    },
    getPercentage(sale) {
      if (+this.price) {
        const value = 100 * sale.amount / +this.price
        return '' + (Math.round(100 * value) / 100)
      }
      return ''
    },
    percentageAmountChanged(event, sale) {
      const newPrice = +this.price * (+event.value) / 100
      sale.amount = Math.round(newPrice * 100) / 100
    },
  },
  mounted() {
  },
}
</script>
<style scoped lang="less">
.discount-label {
  font-weight: bold;
  background: #ccc;
  padding: 5px;
}
</style>
