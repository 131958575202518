<template>
  <div>
    <div class="sale-price">
      <div class="discounts" v-if="discounts && discounts.length">
        <div class="badge badge-dark">{{ elt.price | currency }}</div>
        <div class="badge badge-light" v-for="discount in discounts" :key="discount.id">
          <span v-if="discount.amount">-{{ discount.amount }}€</span>
          <span v-if="discount.percentage">-{{ discount.percentage }}%</span>
          <span v-if="discount.comments" v-b-tooltip="discount.comments">
            &nbsp;<i class="fa fa-info-circle"></i>
          </span>
        </div>
      </div>
      <span class="badge badge-light" v-if="extraSales.length">
        <i class="fa fa-warning"></i> Réductions / Suppléments
      </span>
      {{ elt.getPrice() | currency }}
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

export default {
  name: 'SalePrice',
  props: {
    elt: Object,
  },
  data() {
    return {
    }
  },
  computed: {
    discounts() {
      if (this.elt && this.elt.discounts) {
        return this.elt.discounts
      }
      return []
    },
    extraSales() {
      if (this.elt) {
        return this.elt.getSales(true)
      }
      return []
    },
  },
  watch: {
    discounts: function() {},
  },
  methods: {
  },
  mounted() {
  },
}
</script>
<style scoped lang="less">
  .discounts {
    display: inline-block;
  }
  .discounts .badge{
    margin: 0 1px;
  }
</style>
