<template>
  <span
    class="activity-extra-sale-button"
    v-if="isAllowed"
  >
    <a href @click.prevent="showModal" class="btn btn-xs btn-secondary">
      <i class="fas fa-plus-circle"></i>
      Réduction / Supplément
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      cancel-title="Annuler"
      :ok-disabled="isDisabled()"
      @ok.prevent="onSave"
      ok-variant="primary"
      :ok-title="deletedSales.length ? 'Supprimer' : 'Ajouter'"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-plus-circle"></i> Réduction / Supplément
          <span v-if="inscription">{{ inscription.activity.name }}</span>
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <sales-list
        :list="inscription.getSales()"
        :can-delete="inscription.getSales(true)"
        @delete="onDelete"
      ></sales-list>
      <div v-if="deletedSales.length === 0">
        <b>Ajouter une vente</b>
        <b-row>
          <b-col>
            <b-row class="">
              <b-col>
                <b-form-group
                  id="type-group"
                  label="Type"
                  label-for="type"
                  description=""
                >
                  <b-select
                    id="type"
                    v-model="choice"
                  >
                    <b-select-option v-for="choice of choices" :key="choice.id" :value="choice">
                      {{ choice.name }}
                    </b-select-option>
                  </b-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="percent-group"
                  label="Pourcentage"
                  label-for="percent"
                  :description="percentageDescription"
                >
                  <decimal-input
                    id="percent"
                    :disabled="!percentageAllowed"
                    :value="getPercentage()"
                    @focusout="percentageAmountChanged($event)"
                  >
                  </decimal-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="price-group"
                  label="Montant"
                  label-for="price"
                  description="en euros"
                >
                  <decimal-input
                    id="price"
                    v-model="price"
                  >
                  </decimal-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="comments-group"
                  label="Commentaires"
                  label-for="comments"
                  description="Libellé sur la facture. Si vide, Remboursement/Supplément et nom de l'activité"
                >
                  <b-form-input id="comments" v-model="comments"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!isDisabled()">
              <b-col>
                <div :class="isRefund ? 'alert-danger' : 'alert-info'" class="confirm-text">
                  {{ choice.name }} de {{ price | currency }} sur l'activité
                  {{ inscription.activity.name }} de {{ inscription.individual.firstAndLastName() }}.
                  Le montant total sera de {{ totalPrice | currency }}
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <div><b>Supprimer les ventes</b></div>
        <div v-for="sale of deletedSales" :key="sale.id" class="small-text field-line">
          <b-row>
            <b-col>
              {{ sale.label }}
              <div v-if="sale.price > 0">
                <b>La vente sera annulée</b>
              </div>
              <div v-if="sale.price < 0">
                <b>Une vente sera créée pour contrebalancer cette réduction</b>
              </div>
            </b-col>
            <b-col cols="3" class="text-right">{{ sale.price | currency }}</b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import SalesList from '@/components/Invoices/SalesList'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'
import { currency } from '@/filters/texts'

const EXTRA = 1
const REFUND = 2

export default {
  name: 'activity-extra-sale-button',
  mixins: [BackendMixin],
  props: {
    inscription: Object,
    entity: Object,
    youthHome: [Number, String],
  },
  components: {
    DecimalInput,
    SalesList,
  },
  data() {
    return {
      randomId: getRandomId(),
      choice: null,
      price: '',
      percent: '',
      comments: '',
      errorText: '',
      commentsTouched: false,
      deletedSales: [],
    }
  },
  computed: {
    isAllowed() {
      if (this.youthHome) {
        return this.hasAllPerms(['youth.add_youthactivityinscription', 'payments.add_sale'])
      } else {
        return this.hasAllPerms(['activities.add_coreactivityinscription', 'payments.add_sale'])
      }
    },
    choices() {
      return [
        makeChoice({ id: EXTRA, name: 'Supplément', }),
        makeChoice({ id: REFUND, name: 'Réduction', })
      ]
    },
    isRefund() {
      return this.choice && this.choice.id === REFUND
    },
    extraPrice() {
      if (this.choice && this.price) {
        let price = +this.price
        return (this.isRefund) ? -price : price
      }
      return 0
    },
    totalPrice() {
      return this.inscription.getPrice() + this.extraPrice
    },
    percentageDescription() {
      if (this.percentageAllowed) {
        return 'en % de ' + currency(this.inscription.getPrice())
      } else {
        return 'Le prix de base est nul'
      }
    },
    percentageAllowed() {
      return this.inscription.getPrice() !== 0
    },
  },
  watch: {
    inscription: function(newValue, oldValue) {},
    entity: function(newValue, oldValue) {},
    price: function() {},
  },
  methods: {
    ...mapActions(['addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-activity-extra-sale-button' + this.randomId
    },
    isDisabled() {
      if (this.deletedSales.length) {
        return false
      } else {
        return ((this.choice === null) || (isNaN(+this.price)) || (+this.price <= 0))
      }
    },
    onSave() {
      if (this.deletedSales.length) {
        this.cancelSales()
      } else {
        this.addItem()
      }
    },
    async addItem() {
      this.errorText = ''
      try {
        let url = '/api/entity/' + this.entity.id + '/inscriptions/'
        if (this.youthHome) {
          url = '/api/entity/' + this.entity.id + '/youth/activity-inscriptions/'
        }
        url += this.inscription.id + '/add_extra_sale/'
        const backendApi = new BackendApi('post', url)
        let data = {
          price: this.extraPrice,
          comments: this.comments,
        }
        await backendApi.callApi(data)
        this.$bvModal.hide(this.getId())
        if (this.isRefund) {
          await this.addSuccess('La réduction a été ajoutée')
        } else {
          await this.addSuccess('Le supplément a été ajouté')
        }
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async cancelSales() {
      this.errorText = ''
      try {
        let url = '/api/entity/' + this.entity.id + '/inscriptions/'
        url += this.inscription.id + '/cancel_extra_sales/'
        const backendApi = new BackendApi('post', url)
        let data = {
          sales: this.deletedSales.map(elt => elt.id),
        }
        await backendApi.callApi(data)
        this.$bvModal.hide(this.getId())
        await this.addSuccess('Les ventes ont été annulées')
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    getPercentage() {
      if (this.price && this.inscription.getPrice()) {
        const value = 100 * (+this.price) / this.inscription.getPrice()
        return '' + (Math.round(100 * value) / 100)
      }
      return ''
    },
    percentageAmountChanged(event) {
      const newPrice = this.inscription.getPrice() * (+event.value) / 100
      this.price = Math.round(newPrice * 100) / 100
    },
    onDelete(event) {
      this.deletedSales = event.sales
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .confirm-text {
    padding: 3px;
  }
</style>
